import PersistentDrawerRight from "./Drawer";
import UserContext from "../context/UserContext";
// import { UserProvider } from "../context/UserContext";
// import { useContext } from "react";
import { useState, useContext } from "react";
import { useQuery } from "react-query";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Wifi from "@mui/icons-material/Wifi";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";

import axios from "axios";
const DEVICES_ENDPOINT = `${process.env.REACT_APP_API_HOST}/devices`;

async function fetchDevices(user) {
  const devices = axios
    .get(DEVICES_ENDPOINT, {
      headers: {
        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
      },
      params: {
        payload: user.signInUserSession.idToken.payload,
      },
    })
    .then((r) => r.data)
    .catch((e) => console.log(e));

  return devices;
}

export default function DevicesPage() {
  const [devices, setDevices] = useState([]);
  const user = useContext(UserContext);

  const { isLoading, error, data } = useQuery({
    queryKey: ["devices", user],
    queryFn: () => fetchDevices(user),
    onSuccess: (data) => setDevices(data),
  });

  console.log(`devices: ${JSON.stringify(devices)}`);

  function DevicesList() {
    return isLoading ? (
      <CircularProgress />
    ) : (
      <div className="DevicesList">
        <List>
          {devices?.map((device) => (
            <ListItem key={device.id}>
              <ListItemButton component={Link} to={`/devices/${device.id}`}>
                <ListItemText primary={device.id}></ListItemText>
                <Wifi
                  className="DeviceSelect-ConnectionStatus"
                  color={device.status ? "success" : "disabled"}
                  sx={{ fontSize: 35 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Outlet></Outlet>
      </div>
    );
  }

  return (
    <div>
      <Typography variant="h4" marginLeft={5}>
        My devices
      </Typography>

      <DevicesList></DevicesList>

      {/* {devices.map((device) => ( */}
      {/* <li>{device.id}</li> */}
      {/* ))} */}
    </div>
  );
}
