import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
  CartesianGrid,
  ScatterChart,
  Scatter,
} from "recharts";

import { useEffect, useState, useContext } from "react";
import UserContext from "../context/UserContext";
import { DATA_STREAMS } from "../constants/constants";
import axios from "axios";

const MOTION_EVENTS_ENDPOINT = `${process.env.REACT_APP_API_HOST}/motion_events`;

function timestampToHour(timestamp) {
  const date = new Date(timestamp);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  return `${hours}:${minutes}`;
}

async function fetchMotionEvents(deviceId, selectedDate, user) {
  if (selectedDate == null) {
    return;
  }

  const endDate = new Date(new Date(selectedDate).setHours(23, 59, 59));

  const endDay = endDate.toISOString().split("T")[0] + " 00:00:00";

  // TODO when running outside Germany, we would need to fetch for stadDay and endDay
  // and the combine results.
  // TODO token handling should be added
  // TODO check if changing query to less response data would help with performance

  // TODO check if we should have a secondary index so that we can query events by day

  const motionEvents = axios
    .get(MOTION_EVENTS_ENDPOINT, {
      headers: {
        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
      },
      params: {
        payload: user.signInUserSession.idToken.payload,
        day: endDay,
        deviceId: deviceId,
      },
    })
    .then((r) => r.data)
    .then((motionEvents) => enrichEvents(motionEvents))
    .catch((e) => console.log(e));

  return motionEvents;
}

function enrichEvents(motionEvents) {
  const enriched =
    motionEvents === {}
      ? []
      : motionEvents.map((motionEvent) => {
          motionEvent.startTimeTimestamp = new Date(
            motionEvent.startTime
          ).getTime();
          return motionEvent;
        });
  return enriched;
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label-duration">{`Duration: ${payload[1].value} seconds`}</p>
        <p className="label-day-time">{`Time: ${timestampToHour(
          payload[0].value
        )}`}</p>
      </div>
    );
  }

  return null;
};

function Chart(props) {
  const [motionEvents, setMotionEvents] = useState([]);
  const user = useContext(UserContext);

  function getYLegend() {
    if (!props.dataStream) {
      return "Please select a data stream";
    }

    return `${props.dataStream} ${DATA_STREAMS[props.dataStream]?.units}`;
  }

  useEffect(() => {
    fetchMotionEvents(props.deviceId, props.selectedDate, user).then((m) =>
      setMotionEvents(m)
    );
  }, [props.deviceId, props.selectedDate, user]);

  const domain =
    motionEvents && motionEvents[0]
      ? [
          new Date(motionEvents[0]?.startTime).setHours(0, 0, 0, 0),
          new Date(motionEvents[0]?.startTime).setHours(23, 59, 59, 999),
        ]
      : [new Date().setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 999)];

  return (
    <div className="Chart">
      <ResponsiveContainer aspect={3} height={350} className="hey">
        <ScatterChart
          data={motionEvents}
          margin={{ top: 5, right: 20, left: 10, bottom: 50 }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            dataKey="startTimeTimestamp"
            ticks={[
              domain[0],
              new Date(domain[0]).setHours(6, 0, 0, 0),
              new Date(domain[0]).setHours(12, 0, 0, 0),
              new Date(domain[0]).setHours(18, 0, 0, 0),
              domain[1],
            ]}
            tickFormatter={(d) =>
              Math.abs(d) === Infinity ? null : timestampToHour(d)
            }
            domain={domain}
            type="number" //Needed for domain to take effect
          >
            <Label value="Day Time" position="bottom" name="hour" />
          </XAxis>
          <YAxis
            domain={[0, 300]}
            label={{
              value: getYLegend(),
              angle: -90,
              position: "insideLeft",
              offset: 0,
              style: { textAnchor: "middle" },
            }}
          ></YAxis>
          <Tooltip
            content={<CustomTooltip />}
            //TODO move style to css
            wrapperStyle={{
              backgroundColor: "white",
              borderStyle: "ridge",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          />
          <Legend verticalAlign="top" />
          <Scatter
            name={props.dataStream}
            dataKey="durationSeconds"
            fill="#8884d8"
            dot={{ strokeWidth: 6 }}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Chart;
