import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Wifi from "@mui/icons-material/Wifi";
import Tooltip from "@mui/material/Tooltip";
import UserContext from "../context/UserContext";
import { useQuery } from "react-query";

import { useState, useContext } from "react";

import axios from "axios";

const DEVICES_ENDPOINT = `${process.env.REACT_APP_API_HOST}/devices`;

async function fetchDevice(user, deviceId) {
  const url = `${DEVICES_ENDPOINT}/${deviceId}`;

  const device = axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
      },
      params: {
        payload: user.signInUserSession.idToken.payload,
      },
    })
    .then((r) => r.data)
    .catch((e) => console.log(e));

  return device;
}

async function fetchDevices(user) {
  const devices = axios
    .get(DEVICES_ENDPOINT, {
      headers: {
        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
      },
      params: {
        payload: user.signInUserSession.idToken.payload,
      },
    })
    .then((r) => r.data)
    .catch((e) => console.log(e));

  return devices;
}

//TODO maybe pass array of strings instead of props with id property
export default function DeviceSelect(props) {
  const [devices, setDevices] = useState([]);
  const [deviceStatus, setDeviceStatus] = useState(false);
  const user = useContext(UserContext);

  const { isLoading, error, data } = useQuery({
    queryKey: ["devices", user],
    queryFn: () => fetchDevices(user),
    onSuccess: (data) => setDevices(data),
  });

  const { isLoadingDevice, errorDevice, dataDevice } = useQuery({
    queryKey: ["device", user, props.deviceId],
    queryFn: () => fetchDevice(user, props.deviceId),
    onSuccess: (data) => setDeviceStatus(data?.status),
  });

  return (
    <div className="DeviceSelect">
      <Box sx={{ minWidth: 250 }} className="DeviceSelect-Form">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Device</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.deviceId}
            label="Device"
            onChange={props.handleChange}
          >
            {data?.map((el) => {
              return (
                <MenuItem key={el.id} value={el.id}>
                  {" "}
                  {el.id}{" "}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Tooltip title="Live connection status">
        <Wifi
          className="DeviceSelect-ConnectionStatus"
          color={deviceStatus ? "success" : "disabled"}
          sx={{ fontSize: 35 }}
        />
      </Tooltip>
    </div>
  );
}
