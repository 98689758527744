import { useContext } from "react";
import UserContext from "../context/UserContext";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from "react-query";

const VIDEOS_ENDPOINT = `${process.env.REACT_APP_API_HOST}/videos/video`;

async function fetchVideo(user, deviceId, selectedDate, selectedTime) {
  const dateTime =
    selectedDate == null || selectedTime == null
      ? new Date(0, 0, 0)
      : new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(), //day of the month
          selectedTime.getHours(),
          selectedTime.getMinutes(),
          0
        );

  const formatedDateTime = dateTime.toISOString().split(".")[0] + "Z"; //Remove miliseconds from iso string as expected in used format in api

  const video = axios
    .get(VIDEOS_ENDPOINT, {
      headers: {
        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
      },
      params: {
        payload: user.signInUserSession.idToken.payload,
        deviceId: deviceId,
        dateTime: formatedDateTime,
      },
    })
    .then((r) => r.data)
    .catch((e) => console.log(e));

  return video;
}

export default function VidPage(props) {
  const user = useContext(UserContext);

  const { isLoading, error, data } = useQuery({
    queryKey: ["video", user, props.deviceId, props.selectedDate, props.selectedTime],
    queryFn: () => fetchVideo(user, props.deviceId, props.selectedDate, props.selectedTime),
    refetchOnWindowFocus: false,
  });


  if (!props.deviceId) {
    return <div> Please select a device </div>;
  }
  if (!props.selectedDate) {
    return <div> Please select a date </div>;
  }
  if (!props.selectedTime) {
    return <div> Please select a time </div>;
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  if (data == null) {
    return (
      <div>
        {" "}
        Video not found, please select a different time, date or device{" "}
      </div>
    );
  }
  return (
    <div className="VidPage">
      <video
        loop
        controls
        autoPlay={true}
        src={data}
        type="video/mp4"
      ></video>
    </div>
  );
}
