import PersistentDrawerRight from "./Drawer";
import UserContext from "../context/UserContext";
// import { UserProvider } from "../context/UserContext";
import { useContext } from "react";

export default function ProfilePage() {
  const user = useContext(UserContext);
  return <h1>Profile page</h1>
  return (
    <div className="ProfilePage">
      {/* <UserProvider value={props.user}> */}
      <PersistentDrawerRight user={user}/>
      {/* <Body /> */}
      {/* </UserProvider> */}
    </div>
  );
}
