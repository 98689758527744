import PersistentDrawerRight from "./Drawer";
// import UserContext from "../context/UserContext";
// import { UserProvider } from "../context/UserContext";
// import { useContext } from "react";

import UserContext from "../context/UserContext";
import { useState, useContext } from "react";
import { useQuery } from "react-query";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Wifi from "@mui/icons-material/Wifi";
import { Link } from "react-router-dom";
import { Outlet, useParams } from "react-router-dom";

import axios from "axios";
const DEVICES_ENDPOINT = `${process.env.REACT_APP_API_HOST}/devices`;

async function fetchDevice(user, deviceId) {
  const url = `${DEVICES_ENDPOINT}/${deviceId}`;

  const device = axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
      },
      params: {
        payload: user.signInUserSession.idToken.payload,
      },
    })
    .then((r) => r.data)
    .catch((e) => console.log(e));

  return device;
}

export default function DevicePage() {
  const { deviceId } = useParams();
  const [device, setDevice] = useState([]);
  const user = useContext(UserContext);

  const { isLoading, error, data } = useQuery({
    queryKey: [user, deviceId],
    queryFn: () => fetchDevice(user, deviceId),
    onSuccess: (data) => setDevice(data),
  });


  return <h1>{deviceId}</h1>;



}
