import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DATA_STREAMS } from "../constants/constants";

export default function DataStreamSelect(props) {
  return (
    <div className="DataStreamSelect">
      <Box sx={{ minWidth: 250 }} className="DataStreamSelect-Form">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Data stream</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="datastream-select"
            value={props.dataStream}
            label="Data stream"
            onChange={props.handleChange}
          >
            {Object.keys(DATA_STREAMS).map((el) => {
              return (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
}
